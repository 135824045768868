@import "./variables.scss";

div[data-page="Home"] {
  .why-choose-ptm {
    @media (max-width: 1023px) {
      margin-top: 40px;
    }
    margin-top: 80px;

    h2 {
      text-align: center;
      font-weight: bold;
    }

    p {
      margin-top: 20px;
      margin-bottom: 30px;
    }

    .reason {
      padding: 16px 4px;
      position: relative;

      img {
        width: 100%;
        border-radius: 5px;
      }

      .title {
        font-weight: bold;
        text-align: center;
        margin-bottom: 0;
        font-size: 20px;
      }

      .description {
        text-align: center;
        margin-bottom: 0;
        margin-top: 5px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }

  .plan-your-next-trip {
    margin-top: 80px;
    @media (max-width: 1023px) {
      margin-top: 40px;
    }

    .background {
      padding: 80px 0;
      background-size: cover;
      box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.2);
    }

    h2 {
      font-weight: bold;
      text-align: center;
      color: white;
    }

    .process-icon-wrapper {
      margin: 0 auto;
      margin-top: 50px;
      background-color: $primary-color;
      width: 80px;
      height: 80px;
      border-radius: 40px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 40px;
      }
    }

    p {
      margin-bottom: 0;
      color: white;
      font-weight: bold;
      margin-top: 20px
    }

    .content {
      .row > div {
        flex-direction: column;
        justify-content: center;
      }

      ul {
        li {
            color: $primary-color;
            /* Bullet Color */
          }

          li span {
            color: black;
            /* Text Color */
          }
      }

      margin-top: 50px;
      margin-bottom: 50px;

      img {
        width: 100%;
        margin: 12px 0;
      }

      p, h3 {
        text-align: left;
        color: black;
        font-weight: normal;
      }

      h3 {
        font-weight: bold;
        max-width: 350px;
        padding-left: 15px;
      }
    }
  }
}