@import "./variables.scss";

.top-nav {
  z-index: 100;

  .navbar-nav .nav-link {
    padding: 20px 10px !important;
    margin: 0 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    text-align: center;
    border-width: 0 0 2px 0;
    border-style: solid;
    border-color: transparent;
    color: $secondary-color !important;
  }

  .navbar-nav .nav-link.active {
    color: $primary-color !important;
    border-color: $primary-color;
  }
}

.top-header {
  background-color: $light-gray;
  padding-top: 5px;
  padding-bottom: 5px;

  .contact-container {
    color: #000;
    display: flex;
    flex-direction: row;
    height: 30px;
  }

  .contact {
    display: flex;
    align-items: center;
    margin-right: 40px;

    span,
    span a {
      font-size: 13px;
      color: #000;
      text-decoration: none;
    }

    svg {
      margin-right: 5px;
    }
  }

  .dropdown button {
    padding: 0;
    background: transparent;
    border-color: transparent;
    color: gray;
    font-weight: bold;

    &:active {
      background-color: transparent;
      color: gray;
      border-color: transparent;
    }

    &:focus-visible {
      border-color: transparent;
      box-shadow: none;
    }
  }

  @media (max-width: 1023px) {
    .contact {
      margin-right: 20px;
    }
  }

  @media (max-width: 510px) {
    .contact svg {
      margin-right: 3px;
    }
  }
}

.footer {
  background-color: $dark;

  .contact, .services, .social-media {
    color: white;
    padding-top: 50px;
    padding-bottom: 50px;

    h4 {
      font-weight: bold;
    }

    a {
      color: white;
      text-decoration: none;
    }

    .underline {
      width: 100px;
      height: 2px;
      background-color: $primary-color;
      margin-bottom: 30px;
    }
  }

  .contact {
    p {
      margin-bottom: 0;
      padding-right: 30px;

      svg {
        margin-right: 10px;
      }
    }
  }

  .services {
    .col-6 {
      margin-bottom: 10px;
    }
  }

  .social-media {
    text-align: center;

    .social-media-wrapper {
      margin-bottom: 30px;

      svg {
        margin-left: 5px;
        margin-right: 5px;
      }
    }

    p {
      margin-bottom: 0;
    }

    .underline {
      margin: 0 auto;
      margin-bottom: 30px;
    }

    input {
      display: block;
      margin: 0 auto;
      width: 80%;
      border-radius: 5px;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    button {
      background-color: $primary-color;
      border-color: $primary-color;
    }
  }

  p.all-right-reserved {
    margin-bottom: 0;
    text-align: center;
    padding: 20px;
    color: white;
    background-color: $all-right-reserved;
  }
}

.not-found {
  padding: 100px 0;

  p {
    text-align: center;
    font-size: 50px;
    font-weight: bold;
    margin: 0;
    color: $secondary-color;
  }

  .code {
    font-size: 100px;
  }

  .text {
    margin-top: -20px;
  }
}
