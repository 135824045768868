div[data-page="Our-Services"] {
  .content {
    margin-top: 80px;
    margin-bottom: 80px;

    @media (max-width: 1023px) {
      margin-top: 40px;
      margin-bottom: 40px;
    }

    img {
      width: 100%;
      margin: 12px 0;
    }

    p {
      text-align: left;
      color: black;
      font-weight: normal;
      margin-top: 30px;
    }

    h2 {
      text-align: center;
      font-weight: bold;
      margin-bottom: 30px;
    }
  }

  .services {
    .service {
      position: relative;
    }

    span {
      position: absolute;
      bottom: 20px;
      font-size: 20px;
      font-weight: bold;
      color: white;
      left: 0;
      text-transform: uppercase;
      width: 100%;
      text-align: center;
    }
  }
}