@import "./variables.scss";

div[data-page="Contact"] {
  h2 {
    font-weight: bold;
  }

  .map-wrapper {
    margin-top: 32px;
    padding-left: 20px;
    height: 100%;
  }

  .content {
    margin-top: 80px;
    margin-bottom: 80px;

    @media (max-width: 1023px) {
      margin-top: 40px;
      margin-bottom: 40px;
    }

    button {
      background-color: $primary-color;
      border-color: $primary-color;
    }

    input, textarea {
      &:active, &:focus {
        border-color: $primary-color;
        box-shadow: 0 0 0 0.25rem $box-shadow-color;
      }
    }
  }
}